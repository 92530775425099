import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './Main.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function TicketDetails(pdfData){
    return(
        <div className="content">
            <center>
                {pdfData.data && (
                    <Document
                    file={{ data: pdfData.data }}
                    onLoadError={(error) => console.error('Error loading PDF:', error)}>
                    <Page renderTextLayer={false} pageNumber={1} />
                    </Document>
                )} 
            </center>
        </div>
    );
}

export default TicketDetails;