import React,{ useState } from 'react';
import axios from 'axios';
import './Login.css';

const ForgotPass=()=>{
    const [email_id, setEmailid] = useState('');
    let serverUrl = 'http://localhost:5000';

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await axios.post(serverUrl+'/api/forgot-password', { email_id });
          console.log(response.data);
          setEmailid('');
          alert('Reset Link Sent successfully');
        } catch (error) {
          // Handle error responses here (e.g., show an error message)
          console.error(error.response.data);
        }
    };


    return(
        <div className='container'>
            <form onSubmit={handleSubmit} >
                <center>
                    <div  className='lbl'>
                        <label>
                            Email id:
                            <input type="email" name="email_id" value={email_id} onChange={(e) => setEmailid(e.target.value)}/>
                        </label>
                    </div>
                    <br />
                    <button type="submit" className='btn'>Send Reset Link</button>
                    <br />
                </center>
            </form>
        </div>
    );
}

export default ForgotPass;