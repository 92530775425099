// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css';
import mainimage from '../../images/Flyguy Icon.jpeg'

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://testapi.datascalp.com/apiv1/adminprotal/signinAdminuser', {
        email,
        password,
      });
      console.log(response.data);
      setEmail('');
      setPassword('');
      navigate('/main');
    } catch (error) {
      // Handle error responses here (e.g., show an error message)
      console.error(error.response.data);
    }
  };

  return (
    <div className="container">
      <div className="login-wrapper">
      <img src={mainimage}  alt='main' className='logo'/>
        <h2 className="login-heading">Login</h2>
        <form method="post" onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email id:</label>
            <input type="email" id="email" name="email_id" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <button type="submit" className="login-btn">
            Log In
          </button>
          <div className="forgot-password">
            <a href="/">Forgot password?</a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
