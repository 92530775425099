import React from 'react';
import './Main.css';

function UserProfile(props) {
  const data = props.data?.result;

  if (!data || !data.birthdate || !data.email || !data.firstname || !data.lastname || !data.phonenumber || !data.user_id) {
    // If any of the required data properties are missing or falsy, render an error state or a message
    return (
      <div className="content">
        <center>
          <p></p>
        </center>
      </div>
    );
  }

  const birth_date = data.birthdate;
  const email = data.email;
  const first_name = data.firstname;
  const last_name = data.lastname;
  const phone_no = data.phonenumber;
  const user_id = data.user_id;

  return (
    <div className="content">
      <p><b>First Name:</b> {first_name}</p>
      <p><b>Last Name:</b> {last_name}</p>
      <p><b>Birth Date:</b> {birth_date}</p>
      <p><b>Email:</b> {email}</p>
      <p><b>Phone Number:</b> {phone_no}</p>
      <p><b>User Id:</b> {user_id}</p>
    </div>
  );
}

export default UserProfile;
