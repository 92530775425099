import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Register.css';

const Register = () =>{
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [birthdate, setBirthdate] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (password !== confirmPassword) {
            console.error('Passwords do not match');
            return;
        }

        const userData = {
            firstname: firstName,
            lastname: lastName,
            email: email,
            password: password,
            repassword: password,
            phonenumber: phoneNumber,
            birthdate: birthdate
        };
    
        try {
            const response = await axios.post('https://testapi.datascalp.com/apiv1/adminprotal/signupAdminuser', userData);
            console.log(response.data);
            setFirstName('');
            setLastName('');
            setPassword('');
            setConfirmPassword('');
            setPhoneNumber('');
            setEmail('');
            setBirthdate('');
            alert('User created successfully');
            navigate('/login');
        } catch (error) {
          // Handle error responses here (e.g., show an error message)
          console.error(error.response.data);
        }
    };


    return(
        <div className='Container'>
            <div>
                <center><h2>Create new User</h2></center>
            </div>
            <form className='form' onSubmit={handleSubmit}>
                <center>
                    <div  className='lbl'>
                        <label>
                            <input type="text" name="first_name" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                        </label>
                    </div>
                    <div  className='lbl'>
                        <label>
                            <input type="text" name="last_name" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                        </label>
                    </div>
                    <div  className='lbl'>
                        <label>
                            <input type="email" name="email_id" placeholder="Email Id" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </label>
                    </div>
                    <div  className='lbl'>
                        <label>
                            <input type="password" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                        </label>
                    </div>
                    <div  className='lbl'>
                        <label>
                            <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                        </label>
                    </div>
                    <div  className='lbl'>
                        <label>
                            <input type="number" name="phone_number" placeholder="Phone number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
                        </label>
                    </div>
                    <div  className='lbl'>
                        <label>
                            <input type="date" name="birthdate" placeholder="Birth Date" value={birthdate} onChange={(e) => setBirthdate(e.target.value)}/>
                        </label>
                    </div>
                    <button type="submit" className='btn'>Register</button>
                </center>
            </form>
        </div>
    )
}

export default Register;