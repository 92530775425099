import React from 'react';
import {  Routes, Route } from "react-router-dom";
import Login from './components/login/Login';
import ForgotPass from './components/login/ForgotPass';
import Register from './components/register/Register';
import MainApp from './components/main/Main';

function App() {
  return (
    <div className="App">
        <Routes>
        <Route path="/" component={Login} element={<Login />} />
        <Route path="/forgot-password" component={ForgotPass} element={<ForgotPass />} />
        <Route path="/register" component={Register} element={<Register />} />
        <Route path="/main" component={MainApp} element={<MainApp />} />
        </Routes>
    </div>
  );
}

export default App;
