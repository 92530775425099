import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import TicketDetails from './TicketDetails';
import TripDetails from './TripDetails';
import UserProfile from './UserProfile';
import axios from 'axios';
import moment from 'moment';
import './Main.css';

function MainApp() {
  const [activeLink, setActiveLink] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [pdfData, setPdfData] = useState(null);
  const [userData, setUserData] = useState('');
  const [tripData, setTripData] = useState('');
  const [ArrDate, setArrDate] = useState('');
  const [DepDate, setDepDate] = useState('');
  const [bookingData, setBookingData] = React.useState(null);
  console.log("ArrDate",ArrDate);
  console.log("DepDate",DepDate);

  const handleLinkClick = (linkId) => {
    setActiveLink(linkId);
  };

  const handleSearch = async(inputValue) => {
    setSearchInput(inputValue);
    console.log("Active link "+searchInput)

    console.log("Active link "+activeLink)
    if (activeLink === 1) {
      try{
        const dataIn = {
          airlinepnr: inputValue
        }
        const response = await axios.post('https://api.datascalp.com/apiv1/flightbooking/getuniqueId',dataIn);
        const UniqueID = {
          pnr_id: response.data.result.pnr_id
        }
        const responses = await axios.post('https://api.datascalp.com/apiv1/adminprotal/userProfileDetails',UniqueID);
        console.log(responses.data);
        //setUserData(JSON.stringify(response.data.result));
        setUserData(responses.data);
      }catch (error) {
        console.error(error.response.data);
      }
    } 
    else if (activeLink === 2) {
      try {        
      const dataIn = {
        airlinepnr: inputValue
      }
      const response = await axios.post('https://api.datascalp.com/apiv1/flightbooking/getuniqueId',dataIn);
      const UniqueID = response.data.result.pnr_id;
      
        const responses = await axios.get('https://api.datascalp.com/apiv1/flightbooking/flightbookingticket?pnr_id='+ UniqueID,{
        responseType: 'arraybuffer',});
        const data = new Uint8Array(responses.data);
        console.log("called "+activeLink)
        setPdfData(data);
      } catch (error) {
        console.error(error.response.data);
      }
    }
    else if(activeLink === 3){
      try{
        const dataIn = {
          airlinepnr: inputValue
        }
        const response = await axios.post('https://api.datascalp.com/apiv1/flightbooking/getuniqueId',dataIn);
        const UniqueID = {
          pnr_id: response.data.result.pnr_id
        }
        const responses = await axios.post('https://api.datascalp.com/apiv1/flightbooking/tripdetailsapi',UniqueID);
        const ArrivalDateTime = moment(responses.data.result.TripDetailsResponse.TripDetailsResult.TravelItinerary.ItineraryInfo.ReservationItems[0].ReservationItem.ArrivalDateTime).format("DD-MM-YYYY HH:mm");
        const DepartureDateTime = moment(responses.data.result.TripDetailsResponse.TripDetailsResult.TravelItinerary.ItineraryInfo.ReservationItems[0].ReservationItem.DepartureDateTime).format("DD-MM-YYYY HH:mm");
        setTripData(JSON.stringify(responses.data.result.TripDetailsResponse.TripDetailsResult.TravelItinerary.ItineraryInfo.CustomerInfos));
        setBookingData(responses.data.result.TripDetailsResponse.TripDetailsResult.TravelItinerary);
        setArrDate(ArrivalDateTime);
        setDepDate(DepartureDateTime);

      }catch (error) {
        console.error("---------------------------------",error);
      };
    }
  };

  return (
    <div className='body'>
      <Navbar handleSearch={handleSearch} />
      <Sidebar onLinkClick={handleLinkClick} />
      {activeLink === 1 && <UserProfile data={userData} />}
      {activeLink === 2 && <TicketDetails data={pdfData} />}
      {activeLink === 3 && <TripDetails data={tripData} bookingData={bookingData} ArrDate={ArrDate} DepDate={DepDate}/>}
    </div>
  );
}

export default MainApp;
