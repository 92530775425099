import React from 'react';
import './Main.css';

function TripDetails(props){
    console.log(props.data)
    console.log(props.bookingData)
    console.log("DepDate-------------------",props.DepDate)
    console.log("ArrDate------------",props.ArrDate);
    if(props.data){
    const jsonData = JSON.parse(props.data)
    
    const DisplayData=jsonData.map(
        (info)=>{
            console.log("=======================",info);
            return(
                <tr>
                    <td>{info.CustomerInfo.PassengerType}</td>
                    <td>{info.CustomerInfo.PassengerTitle} {info.CustomerInfo.PassengerFirstName} {info.CustomerInfo.PassengerLastName}</td>
                    <td>{info.CustomerInfo.ItemRPH}</td>
                    <td>{info.CustomerInfo.eTicketNumber}</td>
                    <td>{info.CustomerInfo.DateOfBirth}</td>
                    <td>{info.CustomerInfo.EmailAddress}</td>
                    <td>{info.CustomerInfo.Gender}</td>
                    <td>{info.CustomerInfo.PassengerNationality}</td>
                    <td>{info.CustomerInfo.PassportNumber}</td>
                    <td>{info.CustomerInfo.PhoneNumber}</td>
                    <td>{props.DepDate}</td>
                    <td>{props.ArrDate}</td>
                </tr>
            )
        }
    )
    return(
        <div className="content">
            <center>
            <div>
            <p><b>Booking Status : </b>{props.bookingData.BookingStatus}</p>
            <br /> 
            <p><b>Unique ID :</b> {props.bookingData.UniqueID}</p><br />
            </div>

                <table class="table-format">
                    <thead>
                        <tr>
                        <th>Passenger Type</th>
                        <th>Passenger Name</th>
                        <th>ItemRPH</th>
                        <th>eTicketNumber</th>
                        <th>Date Of Birth</th>
                        <th>Email Address</th>
                        <th>Gender</th>
                        <th>Passenger Nationality</th>
                        <th>Passport Number</th>
                        <th>Phone No</th>
                        <th>Arrival DateTime</th>
                        <th>Departure DateTime</th>
                        </tr>
                    </thead>
                    <tbody>
                        {DisplayData}
                    </tbody>
                </table>
            </center>
        </div>
    );
    }
}

export default TripDetails;

