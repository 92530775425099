import React, { useState } from 'react';
import './Main.css';
import mainimage from '../../images/Flyguy Icon.jpeg'


function Navbar({ handleSearch }) {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearchClick = (e) => {
    handleSearch(inputValue);
  };

  return (
    <div className='body'>
        <div className="container"  width="100%">
            <nav className="navbar">
                <div class="navbar-brand">
                <img src={mainimage}  alt='main' className='logo1'/>
                </div>
                <div>
                    <label>Search by PNR</label>
                    <input type="text" placeholder="Search.."  value={inputValue} onChange={handleInputChange} />
                    <button className="search-button" onClick={handleSearchClick}>Search</button>
                </div>
                <div>
                    <label>Search by user</label>
                    <input type="text" placeholder="Search.." />
                    <button className="search-button">Search</button>
                </div>
            </nav>
        </div>
    </div>
  );
}

export default Navbar;