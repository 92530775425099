import React, { useState } from 'react';
import './Main.css';

function Sidebar(props) {
    const [activeLink, setActiveLink] = useState(null);

    const onSideLinkClick = (id) => {
        props.onLinkClick(id);
        setActiveLink(id);
    };
    
    return (
        <div className='body'>
            <div id="sidenav" className="sidenav">
                <a href="#user" class="sidebar-link" onClick={() => onSideLinkClick(1)} className={activeLink === 1 ? 'active' : ''}>User Profile</a>
                <a href="#ticket" class="sidebar-link" onClick={() => onSideLinkClick(2)} className={activeLink === 2 ? 'active' : ''}>Ticket Details</a>
                <a href="#trip" class="sidebar-link" onClick={() => onSideLinkClick(3)} className={activeLink === 3 ? 'active' : ''}>Trip Details</a>
                <a href="#payment" class="sidebar-link" onClick={() => onSideLinkClick(4)} className={activeLink === 4 ? 'active' : ''}>Payment Transaction</a>
                <a href="#void" class="sidebar-link" onClick={() => onSideLinkClick(5)} className={activeLink === 5 ? 'active' : ''}>void Transaction</a>
                <a href="#refund" class="sidebar-link" onClick={() => onSideLinkClick(6)} className={activeLink === 6 ? 'active' : ''}>Refund Transaction</a>
                <a href="#fare" class="sidebar-link" onClick={() => onSideLinkClick(7)} className={activeLink === 7 ? 'active' : ''}>Fare rules</a>
            </div>
        </div>
    );
}


export default Sidebar;